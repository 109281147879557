import React, {useEffect, useState} from 'react';
import Table from "../../components/Table/Table";
import axios from "axios";
import styled from "styled-components";
import Side from "../../components/Side/Side";
import {Helmet} from "react-helmet";

function HSPopUp(){
    const urlParams = new URL(window.location.href).searchParams
    const code = urlParams.get('HS')
    const codeNumber = urlParams.get('codeNumber')
    const [parentData, setParentData] = useState([])
    const [childData, setChildData] = useState([])
    const [connectData, setConnectData] = useState([])

    //HS detail data
    useEffect(() => {
        axios.post("/api/hs/hsDetail",{code:code})
            .then((response)=>{
                // console.log(response)
                const changeParentData = response.data.data.map( item => ({
                    code: item.hs_code,
                    def_kr: item.def_kr
                }))
                const changeChildData = response.data.childHS.map( item => ({
                    code: item.hs_code,
                    def_kr: item.def_kr
                }))
                setParentData(changeParentData)
                setChildData(changeChildData)
            })
            .catch((error) => {
                console.error("Error ipcDetail axios request", error)
            })
    },[code])

    //HS 연계표
    useEffect(()=>{
        axios.post("/api/hs/findHSConnect", {code: code})
            .then((response) => {
                let ksicCode = response.data.data[0].ksic_code
                if(ksicCode){getConnectData(ksicCode)}
            })
            .catch((error) => {
                console.log("Error findHsConnect axios request", error)
            })
    }, [code])


    const getConnectData = (ksicCode) => {
        axios.post("/api/ksic/ksicConnectCpc", {code: ksicCode})
            .then((response) => {
                // console.log(response)
                const data = response.data.data.map(item => ({
                    code: item.c_code,
                    WIPO_category: item.WIPO_category,
                    WIPO_division: item.WIPO_division,
                    GPT_division: item.GPT_division,
                    GPT_division_description: item.GPT_division_description,
                    division_description: item.division_description
                }))
                setConnectData(data)
            })
            .catch((error) => {
                console.log("Error ksicConnectCpc axios request", error)
            })
    }

    return(
        <div style={{display: "flex", justifyContent:"center"}}>
            <Helmet>
                <title>HS 상하위 품목 보기</title>
                <meta name="description" content={`HS 코드(품목분류코드) 중 ${code}의 상하위 기술코드를 상세하게 확인할 수 있는 팝업창입니다.`} />
                <meta name="robots" content="index, follow, max-snippet:-1" />
                <meta property="og:type" content="article" />
                <meta name="og:title" content="HS 상하위 품목 보기" />
                <meta property="og:description" content={`HS 코드(품목분류코드) 중 ${code}의 상하위 기술코드를 상세하게 확인할 수 있는 팝업창입니다.`} />
                <meta name="og:site_name" content="search code" />
            </Helmet>
            <Side />
            <Center>
                <Title>상하위 품목 검색: {code}</Title>
                <Parent><a style={{fontWeight: 'bold'}}>해당 분류 <a style={{fontWeight: 'bolder'}}>{code}</a>의 상위
                    목록</a></Parent>
                <Table tableData={parentData} codeNumber={codeNumber}/>
                <Child><a style={{fontWeight: 'bold'}}>해당 분류 <a style={{fontWeight: 'bolder'}}>{code}</a>의 하위
                    목록</a></Child>
                {childData.length === 0 ?
                    <NoData>검색 결과 없음</NoData>
                    :
                    <Table tableData={childData} codeNumber={codeNumber}/>
                }
                <div style={{marginTop: '100px', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px'}}>{code}의
                    선도 기업 보러가기
                </div>
                <div style={{marginBottom: '30px'}}>
                    <table>
                        <colgroup>
                            <col width="8%"/>
                            <col width="8%"/>
                            <col width="12%"/>
                            <col width="20%"/>
                            <col width="20%"/>
                            <col width="25%"/>
                        </colgroup>
                        <tr style={{borderBottom: "1px solid #4A506B", borderTop: "1px solid #4A506B"}}>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>연계 코드(CPC)</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>산업</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>대분류</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>중분류</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>소분류</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>정의</th>
                        </tr>
                        {connectData.length > 0 ? (connectData.map(item => (
                            <tr style={{borderBottom: '1px solid #ddd'}}>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#000", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{item.code}
                                    <a href={"https://test.researchall.net/popup/findCPC/" + item.code} target="_blank" rel="noopener" style={{display: "flex", justifyContent: "center", alignItems: "center", color: "black", cursor: 'pointer'}}>
                                        <button style={{cursor: 'pointer', padding: '5px', borderRadius: '5px', backgroundColor: "#4671E9", color: "aliceblue", fontWeight: "bold", border: "1px solid #ddd", marginTop: "10px"}}>선도기업<br/> 보러가기</button>
                                    </a>
                                </td>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{item.WIPO_category}</td>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{item.WIPO_division}</td>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center",padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{item.GPT_division}</td>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{item.GPT_division_description}</td>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{item.division_description}</td>
                            </tr>
                        ))) : (
                            <tr>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}} colSpan={6}>검색 결과 없음</td>
                            </tr>
                        )}
                    </table>
                </div>
            </Center>
            <Side/>
        </div>
    )
}

export default HSPopUp;

const Parent = styled.div`
    padding: 13px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #dfdfdf;
`;

const Child = styled.div`
    margin-top: 30px;
    padding: 13px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #dfdfdf;
`;

const NoData = styled.div`
    border-top: 2px solid #585858;
    border-bottom: 2px solid rgba(49, 49, 49, 0.2);
    padding: 13px;
    display: flex;
    justify-content: center;
`;
const Center = styled.div`
    width: 50%;
    @media ${({theme}) => theme.device.tabletL} {
        width: 90%;
    }
`;
const Title = styled.h1`
    text-align: center;
    padding: 10px;
    font-weight: bolder;
    font-size: 20px;
    margin-bottom: 20px;
`;