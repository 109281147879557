import React from "react";
import styled from "styled-components";
import TechDnaLogo from "../../images/ResearchAll.png"
import top_poster from "../../images/맞춤형 보고서 탑배너.png"

function MenuBar(){
    const goToTechDna = () => {
        window.open("https://researchall.net/home")
    }

    const goToCustomReport = () => {
        window.open("https://researchall.net/Report/CustomIntro")
    }

    return(
        <>
            <div style={{display: "flex", justifyContent: "center", backgroundColor:"#F8F8FD"}}>
                <BannerImg src={top_poster} onClick={goToCustomReport} />
            </div>
            <MenuWrap>
                <Side/>
                <Center>
                    <TechImg src={TechDnaLogo} alt="TechDnaLogo" onClick={goToTechDna}/>
                    <ul>
                        <MainMenu>
                            연계표
                            <div>
                                <ul>
                                    <a title="CPC-IPC 연계표 페이지" href="/search_CPC_IPC_Link"><SubMenu>CPC - IPC</SubMenu></a>
                                    <a title="KSIC-HS 연계표 페이지" href="/search_KSIC_HS_Link"><SubMenu>KSIC - HS</SubMenu></a>
                                </ul>
                            </div>
                        </MainMenu>
                    </ul>

                    <ul>
                        <MainMenu>
                            검색
                            <div>
                                <ul>
                                    <a title="CPC 특허분류 코드 조회" href="searchCPC"><SubMenu>CPC</SubMenu></a>
                                    <a title="IPC 국제 특허 분류 코드 검색 조회" href="/searchIPC"><SubMenu>IPC</SubMenu></a>
                                    <a title="KSIC 한국 표준 산업 분류 코드 검색 조회" href="/searchKSIC"><SubMenu>KSIC</SubMenu></a>
                                    <a title="HS 품목 분류 코드 검색 조회" href="/searchHS"><SubMenu>HS</SubMenu></a>
                                </ul>
                            </div>
                        </MainMenu>
                    </ul>

                    <ul>
                        <MainMenu>
                            정의
                            <div>
                                <ul>
                                    <a title="CPC 특허분류 정의" href="/aboutCPC"><SubMenu>CPC</SubMenu></a>
                                    <a title="IPC 국제 특허 분류 코드 정의" href="/aboutIPC"><SubMenu>IPC</SubMenu></a>
                                    <a title="KSIC 한국 표준 산업 분류 코드 정의" href="/aboutKSIC"><SubMenu>KSIC</SubMenu></a>
                                    <a title="HS 코드 정의" href="/aboutHS"><SubMenu>HS</SubMenu></a>
                                </ul>
                            </div>
                        </MainMenu>
                    </ul>

                </Center>
                <Side/>
            </MenuWrap>
        </>
    )
}

export default MenuBar;

const MenuWrap = styled.div`
    display: flex;
    border-bottom: 1px solid #ddd;
    text-align: center;
    @media ${({theme}) => theme.device.tablet} {
        display: flex;
        border-bottom: 2px solid #8a8a8a;
        text-align: center;
    }
`;
const Side = styled.div`
    width: 25%;
    @media ${({theme}) => theme.device.tabletL} {
        display: none;;
    }
`;
const Center = styled.div`
    width: 50%;
    @media ${({theme}) => theme.device.tabletL} {
        width: 90%;
    }
`;
const MainMenu = styled.li`
    padding: 20px 30px;
    list-style: none;
    cursor: pointer;
    float: right;
    font-weight: bolder;
    letter-spacing: -0.8px;
    font-size: 16px;
    &:hover{
        color: #587feb;
    }
    ul{
        display: none;
    }
    &:hover{
        ul{
            display: block;
        }
    }
    a {
        color: black;
        &:hover {
            color: #587feb;
        }
    }
    div{
        display: flex;
        justify-content: center;
        text-align: center;
        ul{
            position: absolute;
            margin-top: 20px;
            padding: 0;
            border: 1px solid #ddd;
            background-color: white;
        }
    }
    @media ${({theme}) => theme.device.tabletL} {
        padding: 20px 40px;
        font-size: 20px;
    }
    @media ${({theme}) => theme.device.tablet} {
        padding: 25px 30px;
        font-size: 30px;
    }
`;
const SubMenu = styled.li`
    padding: 12px 40px 12px 40px;
    font-weight: normal;
    letter-spacing: 1px;
    color: black;
    font-size: 15px;
    &:hover{
        color: #587feb;
        font-weight: bold;
        background-color: #e5e4e2;
    }
    @media ${({theme}) => theme.device.tabletL} {
        padding: 12px 50px 12px 50px;
        font-size: 18px;
    }
    @media ${({theme}) => theme.device.tablet} {
        padding: 20px 60px 20px 60px;
        font-size: 30px;
    }
`;
const TechImg = styled.img`
    width: 18%;
    padding-top: 17px;
    float: left;
    cursor: pointer;
    @media ${({theme}) => theme.device.tabletL} {
        display: none;
    }
`;

const BannerImg = styled.img`
    width: 50%;
    margin: 50px 0px;
    cursor: pointer;
    @media ${({theme}) => theme.device.tabletL} {
        width:100% ;
        margin: 5px 0px;
    }
`;