import React, {useEffect, useState} from 'react';
import Table from "../../components/Table/Table";
import axios from "axios";
import styled from "styled-components";
import Side from "../../components/Side/Side";
import {Helmet} from "react-helmet";

function CPCPopUp(){
    const urlParams = new URL(window.location.href).searchParams
    const code = urlParams.get('CPC')
    let gptCode = code
    const codeNumber = urlParams.get('codeNumber')
    const [parentData, setParentData] = useState([])
    const [childData, setChildData] = useState([])
    const [gptDefData, setGptDefData] = useState()
    let gptDefList = []

    // cpc detail data
    useEffect(() => {
        axios.post("/api/cpc/cpcDetail",{code:code})
            .then((response)=>{
                // console.log("axios response")
                // console.log(response)
                const changeParentData = response.data.data.map( item => ({
                    code: item.c_code,
                    def_kr: item.def_kr
                }))
                const changeChildData = response.data.childCPC.map( item => ({
                    code: item.c_code,
                    def_kr: item.def_kr
                }))
                setParentData(changeParentData)
                setChildData(changeChildData)
            })
            .catch((error) => {
                console.error("Error cpcDetail axios request", error)
            })
    },[code])

    // gpt cpc def data
    useEffect(() => {
        if(gptCode.indexOf('/') != -1){
            gptCode = gptCode.split('/', 1) + '/00'
            axios.post("/api/cpc/gptCpcDef",{code:gptCode})
                .then((response)=> {
                    setGptDefData(response.data.data[0])
                    gptDefList = response.data.data[0]
                    console.log(gptDefList,gptDefData)
                })
                .catch((error) => {
                    console.error("Error cpcDetail axios request", error)
                })
        }
    },[code])

    const gotoResearchALL = () => {
        window.open("https://test.researchall.net/popup/findCPC/" + code)
    }

    return(
        <div style={{display: "flex", justifyContent: "center"}}>
            <Helmet>
                <title>CPC 상하위 기술 보기</title>
                <meta name="description"
                      content={`CPC코드(특허분류코드) 중 ${code}의 상하위 기술코드와 ${code}의 선도기업목록을 확인할 수 있는 팝업창입니다.`}/>
                <meta name="robots" content="index, follow, max-snippet:-1"/>
                <meta name="og:type" content="article"/>
                <meta name="og:title" content="CPC 상하위 기술 보기"/>
                <meta name="og:description"
                      content={`CPC코드(특허분류코드) 중 ${code}의 상하위 기술코드와 ${code}의 선도기업목록을 확인할 수 있는 팝업창입니다.`}/>
                <meta name="og:site_name" content="search code"/>
            </Helmet>
            <Side/>
            <Center>
                <Title>기술 코드 설명: {gptDefData?.c_code}</Title>
                {gptDefData ? (
                    <table style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        borderTop: "1px solid #4a506b",
                        marginBottom: "20px"}}>
                        <colgroup>
                            <col width="8%"/>
                            <col width="12%"/>
                            <col width="20%"/>
                            <col width="20%"/>
                            <col width="25%"/>
                        </colgroup>
                        <thead style={{borderBottom: "1px solid #4A506B"}}>
                        <tr>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>산업</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>대분류</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>중분류</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>소분류</th>
                            <th rowSpan="2" style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>정의</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{gptDefData?.WIPO_category}</td>
                            <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{gptDefData?.WIPO_division}</td>
                            <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{gptDefData?.GPT_division}</td>
                            <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{gptDefData?.GPT_division_description}</td>
                            <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{gptDefData?.division_description}</td>
                        </tr>
                        </tbody>
                    </table>
                ) : (<p></p>)}
                <Parent><a style={{fontWeight: 'bold'}}>해당 분류 <a style={{fontWeight: 'bolder'}}>{code}</a>의 상위 기술</a></Parent>
                <Table tableData={parentData} codeNumber={codeNumber}/>
                <Child><a style={{fontWeight: 'bold'}}>해당 분류 <a style={{fontWeight: 'bolder'}}>{code}</a>의 하위 기술</a></Child>
                {childData.length === 0 ?
                    <NoData>검색 결과 없음</NoData>
                    :
                    <Table tableData={childData} codeNumber={codeNumber}/>
                }
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <ResearchALLButton onClick={gotoResearchALL}>{code} 기술의 선도 기업 보기</ResearchALLButton>
                </div>
            </Center>
            <Side/>
        </div>
    )
}

export default CPCPopUp

const Parent = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #dfdfdf;
`;

const Child = styled.div`
margin-top: 30px;
padding: 10px;
display: flex;
justify-content: center;
text-align: center;
background-color: #dfdfdf;
`;

const ResearchALLButton = styled.button`
width: 45%;
height: 40px;
cursor: pointer;
border-radius: 5px;
border: 1px solid #ddd;
color: aliceblue;
background-color: #4671E9;
font-weight: bold;
font-size: 15px;
margin-top: 20px;
margin-bottom: 20px;
&:hover{
    background - color
: #
    2e5
    bee;
}
`;

const NoData = styled.div`
border-top: 2px solid #585858;
border-bottom: 2px solid rgba(49,49,49, 0.2);
padding: 13px;
display: flex;
justify-content: center;
`;

const Center = styled.div`
width: 50%;
@media ${
    ({theme}) => theme.device.tabletL
} {
    width: 90 %;
}
`;

const Title = styled.h1`
text-align: center;
padding: 10px;
font-weight: bolder;
font-size: 20px;
margin-bottom: 20px;
`;