import React, {useEffect, useState} from 'react';
import Table from "../../components/Table/Table";
import axios from "axios";
import styled from "styled-components";
import Side from "../../components/Side/Side";
import {Helmet} from "react-helmet";

function IPCPopUp(){
    const urlParams = new URL(window.location.href).searchParams
    const code = urlParams.get('IPC')
    const codeNumber = urlParams.get('codeNumber')
    const [parentData, setParentData] = useState([])
    const [childData, setChildData] = useState([])

    // ipc detail data
    useEffect(() => {
        axios.post("/api/ipc/ipcDetail",{code:code})
            .then((response)=>{
                // console.log(response)
                const changeParentData = response.data.data.map( item => ({
                    code: item.ipc_code,
                    def_kr: item.def_kr
                }))
                const changeChildData = response.data.childIPC.map( item => ({
                    code: item.ipc_code,
                    def_kr: item.def_kr
                }))
                setParentData(changeParentData)
                setChildData(changeChildData)
            })
            .catch((error) => {
                console.error("Error ipcDetail axios request", error)
            })
    },[code])

    const gotoResearchALL = () => {
        window.open("https://test.researchall.net/popup/findCPC/" + code)
    }

    return(
        <div style={{display: "flex", justifyContent:"center"}}>
            <Helmet>
                <title>IPC 상하위 기술 보기</title>
                <meta name="description" content={ `IPC코드(국제특허분류코드) 중 ${code}의 상하위 기술코드에 대해 확인할 수 있는 팝업창입니다.`} />
                <meta name="robots" content="index, follow, max-snippet:-1" />
                <meta property="og:type" content="article" />
                <meta name="og:title" content="IPC 상하위 기술 보기" />
                <meta property="og:description" content={ `IPC코드(국제특허분류코드) 중 ${code}의 상하위 기술코드에 대해 확인할 수 있는 팝업창입니다.`} />
                <meta name="og:site_name" content="search code" />
            </Helmet>
            <Side/>
            <Center>
                <Title>상하위 기술 검색: {code}</Title>
                <Parent><a style={{fontWeight: 'bold'}}>해당 분류 <a style={{fontWeight: 'bolder'}}>{code}</a>의 상위
                    기술</a></Parent>
                <Table tableData={parentData} codeNumber={codeNumber}/>
                <Child><a style={{fontWeight: 'bold'}}>해당 분류 <a style={{fontWeight: 'bolder'}}>{code}</a>의 하위
                    기술</a></Child>
                {childData.length === 0 ?
                    <NoData>검색 결과 없음</NoData>
                    :
                    <Table tableData={childData} codeNumber={codeNumber}/>
                }
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <ResearchALLButton onClick={gotoResearchALL}>{code} 기술의 선도 기업 보기</ResearchALLButton>
                </div>
            </Center>
            <Side/>
        </div>
    )
}

export default IPCPopUp;

const Parent = styled.div`
    padding: 13px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #dfdfdf;
`;

const Child = styled.div`
    margin-top: 30px;
    padding: 13px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #dfdfdf;
`;

const NoData = styled.div`
    border-top: 2px solid #585858;
    border-bottom: 2px solid rgba(49, 49, 49, 0.2);
    padding: 13px;
    display: flex;
    justify-content: center;
`;
const Center = styled.div`
    width: 50%;
    @media ${({theme}) => theme.device.tabletL} {
        width: 90%;
    }
`;

const Title = styled.h1`
    text-align: center;
    padding: 10px;
    font-weight: bolder;
    font-size: 20px;
    margin-bottom: 20px;
`;

const ResearchALLButton = styled.button`
width: 45%;
height: 40px;
cursor: pointer;
border-radius: 5px;
border: 1px solid #ddd;
color: aliceblue;
background-color: #4671E9;
font-weight: bold;
font-size: 15px;
margin-top: 20px;
margin-bottom: 20px;
&:hover{
    background - color
: #
    2e5
    bee;
}
`;