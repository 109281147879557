import React, {useEffect, useState} from 'react';
import Table from "../../components/Table/Table";
import {ReactComponent as DetailIcon} from "../../images/ico_link.svg";
import axios from "axios";
import styled from "styled-components";

function KSICPopUp(){
    const urlParams = new URL(window.location.href).searchParams
    const code = urlParams.get('KSIC')
    const codeNumber = urlParams.get('codeNumber')
    const [parentData, setParentData] = useState([])
    const [childData, setChildData] = useState([])
    const [cpcData, setCpcData] = useState([])

    //ksic detail data
    useEffect(() => {
        axios.post("/api/ksic/ksicDetail",{code:code})
            .then((response)=>{
                // console.log(response)
                const changeParentData = response.data.parentKSIC.map( item => ({
                    code: item.ksic_code,
                    def_kr: item.def_kr
                }))
                const changeChildData = response.data.childKSIC.map( item => ({
                    code: item.ksic_code,
                    def_kr: item.def_kr
                }))
                setParentData(changeParentData)
                setChildData(changeChildData)
            })
            .catch((error) => {
                console.error("Error ksicDetail axios request", error)
            })
    },[code])
    
    // 연계 데이터
    useEffect(() => {
        axios.post("/api/ksic/ksicConnectCpc", {code:code})
            .then((response) => {
                // console.log(response.data)
                const connectData = response.data.data.map(item => ({
                    code: item.c_code,
                    WIPO_category: item.WIPO_category,
                    WIPO_division: item.WIPO_division,
                    GPT_division: item.GPT_division,
                    GPT_division_description: item.GPT_division_description,
                    division_description: item.division_description
                }))
                setCpcData(connectData)
            })
            .catch((error) => {
                console.log("Error ksicConnectCpc axios request", error)
            })
    }, [code]);

    return(
        <div style={{display: "flex"}}>
            <Nav/>
            <div style={{width: "50%"}}>
                <Parent><a style={{fontWeight: 'bold'}}>해당 분류 <a style={{fontWeight: 'bolder'}}>{code}</a>의 상위 기술</a></Parent>
                <Table tableData={parentData} codeNumber={codeNumber} />
                <Child><a style={{fontWeight: 'bold'}}>해당 분류 <a style={{fontWeight: 'bolder'}}>{code}</a>의 하위 기술</a></Child>
                <Table tableData={childData} codeNumber={codeNumber} />
                <div style={{marginTop: '100px', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px'}}>{code}의 선도 기업 보러가기</div>
                <div style={{marginBottom: '30px'}}>
                    <table>
                        <colgroup>
                            <col width="8%"/>
                            <col width="8%"/>
                            <col width="12%"/>
                            <col width="20%"/>
                            <col width="20%"/>
                            <col width="25%"/>
                        </colgroup>
                        <tr style={{borderBottom: "1px solid #4A506B", borderTop: "1px solid #4A506B"}}>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>연계 코드(CPC)</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>산업</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>대분류</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>중분류</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>소분류</th>
                            <th style={{background: "#f8f8f8", textAlign: "center", padding: "10px", color: "#222", wordBreak: "keep-all", fontWeight: "500", whiteSpace: "nowrap"}}>정의</th>
                        </tr>
                        {cpcData.length > 0 ? (cpcData.map(item => (
                            <tr style={{borderBottom: '1px solid #ddd'}}>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#000", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>
                                    {item.code}
                                    <a href={"https://test.researchall.net/popup/findCPC/" + item.code}
                                       target="_blank"
                                       rel="noopener"
                                       style={{
                                           display: "flex",
                                           justifyContent: "center",
                                           alignItems: "center",
                                           color: "black",
                                           cursor: 'pointer'
                                       }}>
                                        <button style={{cursor: 'pointer', padding: '5px', borderRadius: '5px', backgroundColor:"#4671E9", color:"aliceblue", fontWeight:"bold", border: "1px solid #ddd", marginTop: "10px"}}>선도기업<br/> 보러가기</button>
                                    </a>
                                </td>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{item.WIPO_category}</td>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{item.WIPO_division}</td>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{item.GPT_division}</td>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{item.GPT_division_description}</td>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}}>{item.division_description}</td>
                            </tr>
                        ))) : (
                            <tr>
                                <td style={{borderBottom: "1px solid rgba(49, 49, 49, 0.2)", textAlign: "center", padding: "10px", color: "#444", wordBreak: "keep-all", letterSpacing: "1px", lineHeight: "1.2"}} colspan={6}>검색 결과 없음</td>
                            </tr>
                        )}
                    </table>
                </div>
            </div>
            <Nav/>
        </div>
    )
}

export default KSICPopUp;

const Nav = styled.div`
    width: 25%;
`;

const Parent = styled.div`
    padding: 13px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #dfdfdf;
`;

const Child = styled.div`
    margin-top: 30px;
    padding: 13px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #dfdfdf;
`;