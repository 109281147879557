import React from "react";
import styled from "styled-components";
import {ReactComponent as DetailIcon} from "../../images/ico_link.svg";

function Table(props){
    const tableHeader = props.tableHeader;
    const tableData = props.tableData;
    const codeNumber = props.codeNumber;

    return(
        <TableWrap>
            <CustomTable>
                <colgroup>
                    <col style={{width: '*'}} />
                    <col style={{width: '80%'}} />
                </colgroup>
                <thead>
                    <tr>
                        {tableHeader && tableHeader.map(headerItem => (
                            <HeadCell>{headerItem}</HeadCell>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableData.length > 0 ? (tableData.map(item => (
                        <tr>
                            {codeNumber === 1 &&
                                <BodyCell style={{ cursor:"pointer" }}>
                                    <a
                                        href={"/detailCPC?CPC=" + item.code + "&codeNumber=" + codeNumber}
                                        target="_blank"
                                        rel="noopener"
                                        style={{display:"flex", justifyContent:"center", alignItems:"center", color:"black"}}
                                    >
                                        {item.code}
                                        <DetailIcon style={{ width:'10px', marginLeft:'3px', cursor:"pointer" }} />
                                    </a>
                                </BodyCell>
                            }
                            {codeNumber === 2 &&
                                <BodyCell style={{ cursor:"pointer" }}>
                                    <a
                                        href={"/detailIPC?IPC=" + item.code + "&codeNumber=" + codeNumber}
                                        target="_blank"
                                        rel="noopener"
                                        style={{display:"flex", justifyContent:"center", alignItems:"center", color:"black"}}
                                    >
                                        {item.code}
                                        <DetailIcon style={{ width:'10px', marginLeft:'3px', cursor:"pointer" }} />
                                    </a>
                                </BodyCell>
                            }
                            {codeNumber === 3 &&
                                <BodyCell style={{ cursor:"pointer" }}>
                                    <a
                                        href={"/detailKSIC?KSIC=" + item.code + "&codeNumber=" + codeNumber}
                                        target="_blank"
                                        rel="noopener"
                                        style={{display:"flex", justifyContent:"center", alignItems:"center", color:"black"}}
                                    >
                                        {item.code}
                                        <DetailIcon style={{ width:'10px', marginLeft:'3px', cursor:"pointer" }} />
                                    </a>
                                </BodyCell>
                            }
                            {codeNumber === 4 &&
                                <BodyCell style={{ cursor:"pointer" }}>
                                    <a
                                        href={"/detailHS?HS=" + item.code + "&codeNumber=" + codeNumber}
                                        target="_blank"
                                        rel="noopener"
                                        style={{display:"flex", justifyContent:"center", alignItems:"center", color:"black"}}
                                    >
                                        {item.code}
                                        <DetailIcon style={{ width:'10px', marginLeft:'3px', cursor:"pointer" }} />
                                    </a>
                                </BodyCell>
                            }
                            {codeNumber === '1' &&
                                <BodyCell style={{ cursor:"pointer" }}>
                                    <a
                                        href={"/detailCPC?CPC=" + item.code + "&codeNumber=" + codeNumber}
                                        style={{display:"flex", justifyContent:"center", alignItems:"center", color:"black"}}

                                    >
                                        {item.code}
                                        <DetailIcon style={{ width:'10px', marginLeft:'3px', cursor:"pointer" }} />
                                    </a>
                                </BodyCell>
                            }
                            {codeNumber === '2' &&
                                <BodyCell style={{ cursor:"pointer" }}>
                                    <a
                                        href={"/detailIPC?IPC=" + item.code + "&codeNumber=" + codeNumber}
                                        style={{display:"flex", justifyContent:"center", alignItems:"center", color:"black"}}
                                    >
                                        {item.code}
                                        <DetailIcon style={{ width:'10px', marginLeft:'3px', cursor:"pointer" }} />
                                    </a>
                                </BodyCell>
                            }
                            {codeNumber === '3' &&
                                <BodyCell style={{ cursor:"pointer" }}>
                                    <a
                                        href={"/detailKSIC?KSIC=" + item.code + "&codeNumber=" + codeNumber}
                                        style={{display:"flex", justifyContent:"center", alignItems:"center", color:"black"}}

                                    >
                                        {item.code}
                                        <DetailIcon style={{ width:'10px', marginLeft:'3px', cursor:"pointer" }} />
                                    </a>
                                </BodyCell>
                            }
                            {codeNumber === '4' &&
                                <BodyCell style={{ cursor:"pointer" }}>
                                    <a
                                        href={"/detailHS?HS=" + item.code + "&codeNumber=" + codeNumber}
                                        style={{display:"flex", justifyContent:"center", alignItems:"center", color:"black"}}
                                    >
                                        {item.code}
                                        <DetailIcon style={{ width:'10px', marginLeft:'3px', cursor:"pointer" }} />
                                    </a>
                                </BodyCell>
                            }
                            <BodyCell>{item.def_kr}</BodyCell>
                        </tr>
                    ))) : (
                        <tr>
                            <BodyCell colspan={2} style={{textAlign: "center"}}>검색 결과 없음</BodyCell>
                        </tr>
                    )}
                </tbody>
            </CustomTable>
        </TableWrap>
    )
}

export default Table;

const TableWrap = styled.div`
    display: flex;
    justify-content: center;
    padding: 0px 0px 5px 0px;
    width: 100%;
`;

const CustomTable = styled.table`
    border-top: 2px solid #585858;
    border-spacing: 0;
    width: 100%;
`;

const HeadCell = styled.th`
    padding: 12px 10px 10px 10px;
    background-color: #dfdfdf;
    text-align: center;
    letter-spacing: 1px;
`;
const BodyCell = styled.td`
    padding: 13px;
    border-bottom: 2px solid rgba(49,49,49, 0.2);
    letter-spacing: 1px;
    line-height: 1.2;
    //text-align: center;
`;

